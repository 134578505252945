<template>
  <div class="step-layout">
    <div class="step-content">
      <v-form class="form-contact" ref="form">
        <v-row>
          <v-col cols="5" md="3">
            <div class="form-title">
              <h1>
                <span><i class="fal fa-lock-alt"></i></span
                >{{ $t("Employee.form.account") }}
              </h1>
            </div>
          </v-col>
          <v-col cols="7" md="9">
            <v-row class="personal-input">
              <v-col cols="6" md="6">
                <label class="label-input">{{
                  $t("Employee.form.selectBank")
                }}</label>
                <v-select
                  outlined
                  dense
                  :items="banks"
                  item-value="id"
                  item-text="name"
                  v-model="employee.bank_id"
                  :hint="`${server_errors.bank_id}`"
                  persistent-hint
                >
                </v-select>
              </v-col>

              <v-col cols="6" md="6">
                <label class="label-input">{{
                  $t("Employee.form.accountName")
                }}</label>
                <v-text-field
                  outlined
                  dense
                  v-model="employee.bank_account_name"
                  :hint="`${server_errors.bank_account_name}`"
                  persistent-hint
                  @keyup="textUppercase"
                  @keypress="isLetter($event)"
                >
                </v-text-field>
              </v-col>

              <v-col cols="12" md="12">
                <label class="label-input">{{
                  $t("Employee.form.accountNumber")
                }}</label>
                <v-text-field
                  outlined
                  dense
                  v-model="employee.bank_account"
                  :hint="`${server_errors.bank_account}`"
                  persistent-hint
                >
                </v-text-field>
              </v-col>
            </v-row>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="5" md="3">
            <div class="form-title">
              <h1>
                <span><i class="fal fa-lock-alt"></i></span
                ><span class="text-alert">*</span
                >{{ $t("Employee.form.account System") }}
              </h1>
            </div>
          </v-col>
          <v-col cols="7" md="9">
            <v-row class="personal-input">
              <v-col cols="12" md="12">
                <label class="label-input"
                  ><span class="text-alert">*</span
                  >{{ $t("Employee.form.email") }}</label
                >
                <v-text-field
                  dense
                  outlined
                  v-model="employee.email"
                  :hint="`${server_errors.email}`"
                  persistent-hint
                >
                </v-text-field>
              </v-col>
            </v-row>
          </v-col>
        </v-row>

        <v-col cols="12" md="12">
          <v-btn
            class="btn-next btn-save-change"
            @click="validateForm"
            :loading="btnLoading"
          >
            Update <span><i class="fal fa-long-arrow-right"></i></span>
          </v-btn>
        </v-col>
      </v-form>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    employee: {},
  },
  data() {
    return {
      btnLoading: false,
      employee_id: this.$route.params.employee_id,
      nameRules: [(v) => !!v || "field required..."],
      banks: [],
      server_errors: {
        bank_id: "",
        bank_account: "",
        email: "",
        bank_account_name: "",
      },
    };
  },
  methods: {
    textUppercase() {
      this.employee.bank_account_name =
        this.employee.bank_account_name.toUpperCase();
    },
    isLetter(e) {
      let char = String.fromCharCode(e.keyCode);
      if (/^[A-Za-z]+$/.test(char)) return true;
      else e.preventDefault();
    },

    validateForm() {
      if (this.$refs.form.validate()) {
        this.saveChange();
      }
    },
    fetchBank() {
      this.$axios.get(`list/banks`).then((res) => {
        if (res.status === 200) {
          this.banks = res.data.data;
        }
      });
    },
    saveChange() {
      this.btnLoading = true;
      const items = {
        bank_id: this.employee.bank_id,
        bank_account_name: this.employee.bank_account_name,
        bank_account: this.employee.bank_account,
        email: this.employee.email,
      };
      this.$axios
        .put(`company/edit/employee/account/info/${this.employee_id}`, items)
        .then((res) => {
          if (res.status === 200) {
            this.btnLoading = false;
            setTimeout(() => {
              this.$notification.OpenNotification_AddItem_OnSuccess(
                "top-right",
                "primary",
                3000
              );
            }, 300);
          }
        })
        .catch((error) => {
          this.btnLoading = false;
          if (error.response.status === 422) {
            let obj = error.response.data.errors;
            if (!obj) {
              obj = error.response.data;
              let res = "";
              for (let [key, value] of Object.entries(obj)) {
                res = value;
              }
              this.$notification.ShowErrors("top-right", "danger", 3000, res);
            } else {
              for (let [key, value] of Object.entries(obj)) {
                this.server_errors[key] = value[0];
                this.$notification.ShowErrors(
                  "top-right",
                  "danger",
                  3000,
                  (this.server_errors[key] = value[0])
                );
              }
            }
          }
          if (error.response.status === 500) {
            this.$notification.ShowErrors(
              "top-right",
              "danger",
              3000,
              "server error"
            );
          }
          this.btnLoading = false;
        });
    },
  },
  created() {
    this.fetchBank();
  },
};
</script>

<style scoped lang="scss">
.form-contact {
  margin-top: 40px;
  width: 900px;
  height: 800px;

  .form-title {
    font-family: $font-family;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;

    h1 {
      font-size: 1.2rem;
      letter-spacing: 1px;
      font-weight: normal;
      color: #0978ea;

      span {
        color: #0978ea;
        margin-right: 5px;
      }
    }
  }

  .personal-input {
    width: 100%;
    height: 100%;
    //  background-color: green;
    padding-top: 20px;
    border-bottom: 1px solid #eeeeee;
    padding-bottom: 20px;
  }
}
</style>
