<template>
  <div class="step-layout">
    <div class="step-content">
      <v-form class="form" ref="form">
        <v-row>
          <v-col cols="5" md="3">
            <div class="form-title">
              <h1>
                <span><i class="far fa-usd-circle"></i></span>{{ $t("Employee.form.work_info") }}
              </h1>
            </div>
          </v-col>
          <v-col cols="8" md="8">
            <v-row class="personal-input">
              <v-col cols="8" md="8" style="position: relative">
                <label class="label-input"><span class="text-alert">*</span>{{ $t("Employee.form.position") }}</label>
                <!-- <v-select
                  v-model="employee.positions"
                  multiple
                  :items="position"
                  item-text="name"
                  item-value="id"
                  dense
                  :hint="`${server_errors.positionId}`"
                  persistent-hint
                  outlined
                >
                </v-select> -->
                <v-autocomplete v-model="employee.positions" :items="position" outlined dense chips small-chips
                  item-value="id" item-text="name" multiple :rules="nameRules" :hint="`${server_errors.positionId}`"
                  persistent-hint></v-autocomplete>
                <div class="add-village" @click="addNewPosition">
                  <i class="fal fa-plus"></i>
                </div>
              </v-col>
              <v-col cols="8" md="8" style="position: relative">
                <label class="label-input"><span class="text-alert">*</span>{{ $t("Employee.form.department") }}</label>
                <v-autocomplete filled dense item-text="name" item-value="id" outlined :items="listDepartment"
                  hide-selected :rules="nameRules" :hint="`${server_errors.company_department_id}`" persistent-hint
                  requried v-model="employee.department_id">
                  <template v-slot:item="data">
                    <template>
                      <v-list-item-content>
                        <v-list-item-title v-html="data.item.name"></v-list-item-title>
                      </v-list-item-content>
                    </template>
                  </template>
                </v-autocomplete>
                <div class="add-village" @click="addNewDepartment">
                  <i class="fal fa-plus"></i>
                </div>
              </v-col>
              <v-col cols="8" md="8" style="position: relative">
                <label class="label-input">{{
                  $t("Employee.form.employee_level")
                }}</label>
                <v-select outlined dense item-value="id" item-text="name" :items="employeeLevel"
                  v-model="employee.level_id" :hint="`${server_errors.level_id}`" persistent-hint>
                </v-select>
                <div class="add-village" @click="addEmployeeLevel">
                  <i class="fal fa-plus"></i>
                </div>
              </v-col>

              <v-col cols="8" md="8" style="position: relative">
                <label class="label-input"><span class="text-alert">*</span>{{ $t("Employee.form.branch") }}</label>
                <v-autocomplete filled dense item-text="branch_name" item-value="id" outlined :items="branches"
                  hide-selected :rules="nameRules" :hint="`${server_errors.company_branch_id}`" persistent-hint requried
                  v-model="employee.company_branch_id">
                  <template v-slot:item="data">
                    <template>
                      <v-list-item-content>
                        <v-list-item-title v-html="data.item.branch_name"></v-list-item-title>
                      </v-list-item-content>
                    </template>
                  </template>
                </v-autocomplete>

                <div class="add-village" @click="addBranch">
                  <i class="fal fa-plus"></i>
                </div>
              </v-col>

              <!-- Start Job Title -->
              <v-col cols="8" md="8" style="position: relative">
                <label class="label-input">{{ $t("Employee.form.jobTitle") }}</label>
                <v-autocomplete filled dense item-text="name" item-value="id" outlined clearable :items="jobTitles"
                  hide-selected persistent-hint
                  v-model="employee.job_title_id">
                  <template v-slot:item="data">
                    <template>
                      <v-list-item-content>
                        <v-list-item-title v-html="data.item.name"></v-list-item-title>
                      </v-list-item-content>
                    </template>
                  </template>
                </v-autocomplete>

                <!-- <div class="add-village" @click="addBranch">
                  <i class="fal fa-plus"></i>
                </div> -->
              </v-col>
              <!-- End Job Title -->

              <!-- Start Education Level -->
              <v-col cols="8" md="8" style="position: relative">
                <label class="label-input">{{ $t("Employee.form.educationLevel") }}</label>
                <v-autocomplete filled dense item-text="name" item-value="id" outlined clearable :items="educationLevels"
                  hide-selected persistent-hint
                  v-model="employee.education_level_id">
                  <template v-slot:item="data">
                    <template>
                      <v-list-item-content>
                        <v-list-item-title v-html="data.item.name"></v-list-item-title>
                      </v-list-item-content>
                    </template>
                  </template>
                </v-autocomplete>

                <!-- <div class="add-village" @click="addBranch">
                  <i class="fal fa-plus"></i>
                </div> -->
              </v-col>
              <!-- End Job Title -->

              <!-- Working Years -->
              <v-col cols="8" md="8" style="margin-top: -30px;">
                <v-checkbox 
                  v-model="employee.is_annual_bonus" 
                  :label="$t('workingYear.is_working_years')" 
                  hide-details
                  class="lfont"
                >
                </v-checkbox>
              </v-col>
              <!-- End Working Years -->

              <v-col cols="8" md="8" style="margin-top: 20px;">
                <label class="label-input"><span class="text-alert">*</span>{{ $t("Employee.form.employment_types")
                }}</label>
                <v-radio-group row v-model="employee.employment_type">
                  <v-radio class="label-input" value="PartTime" label="PartTime">
                  </v-radio>
                  <v-radio class="label-input" value="FullTime" label="FullTime">
                  </v-radio>
                </v-radio-group>
              </v-col>
              <v-col cols="8" md="8" class="mb-0">
                <label class="label-input"><span class="text-alert">*</span>{{ $t("Employee.form.start Date") }}</label>
                <DatePicker class="date-time" style="width: 100%" type="date" valueType="format" slot="activator"
                  :append-to-body="true" v-model="employee.start_date" :rules="nameRules" requried
                  :hint="`${server_errors.start_date}`" persistent-hint></DatePicker>
              </v-col>
              <v-col cols="8" md="8" class="mb-8">
                <v-checkbox v-model="employee.setEndContract" :label="$t('Employee.form.end_date')" hide-details
                  class="lfont"></v-checkbox>
                <div v-if="employee.setEndContract">
                  <DatePicker class="date-time" style="width: 100%" type="date" valueType="format" slot="activator"
                    :append-to-body="true" v-model="employee.end_date" :rules="nameRules" requried
                    :hint="`${server_errors.end_date}`" persistent-hint></DatePicker>
                </div>

              </v-col>
            </v-row>
          </v-col>
        </v-row>

        <div v-if="ManageSalaryPermission">
          <v-row>
            <!-- <v-col cols="5" md="3">
              <div class="form-title">
                <h1>
                  <span><i class="far fa-usd-circle"></i></span>{{ $t("Employee.form.salary") }}
                </h1>
              </div>
            </v-col> -->
            <!-- <v-col cols="8" md="8">
              <v-row class="personal-input">
                <v-col cols="8" md="8">
                  <label class="label-input"
                    ><span class="text-alert">*</span
                    >{{ $t("Employee.form.baseSalary") }}</label
                  >
                  <vuetify-money
                    dense
                    :outlined="true"
                    :options="$store.state.formatSalaryNumber"
                    :clearable="true"
                    v-model="employee.basic_salary"
                    :hint="`${server_errors.basic_salary}`"
                    persistent-hint
                    type="number"
                    class="input-number"
                    :rules="nameRules"
                    required
                  />
                </v-col>
              </v-row>
            </v-col> -->
          </v-row>
          <v-row>
            <v-col cols="5" md="3">
              <div class="form-title">
                <h1>
                  <span><i class="fal fa-money-check-edit-alt"></i></span>{{ $t("Employee.form.benefit") }}
                </h1>
              </div>
            </v-col>
            <v-col cols="7" md="9">
              <v-row class="personal-input">
                <v-col class="d-flex flex-row pa-0" cols="12" md="12" v-for="(item, index) in employee.benefits"
                  :key="index">
                  <v-col cols="4" md="4">
                    <label class="label-input">{{
                      $t("Employee.form.benefit")
                    }}</label>
                    <v-select outlined dense v-model="item.benefit_id" :items="listBenefits" item-value="id"
                      item-text="name" :hint="`${server_errors.benefit_id}`" persistent-hint>
                    </v-select>
                  </v-col>
                  <v-col cols="8" md="8">
                    <label class="label-input">{{
                      $t("Employee.form.benefitValue")
                    }}</label>
                    <vuetify-money dense :outlined="true" :options="$store.state.formatSalaryNumber" :clearable="true"
                      v-model="item.value" :hint="`${server_errors.value}`" persistent-hint type="number"
                      class="input-number" :rules="nameRules" required />
                  </v-col>
                  <span>
                    <div class="removeContact" v-show="employee.benefits.length > 1">
                      <i class="fas fa-minus-circle" @click="removeFieldBenefit(item)"></i>
                    </div>
                  </span>
                </v-col>
                <v-col cols="12">
                  <div class="btn-addFieldContact" @click="addFieldBenefit()">
                    <span class="mr-2"><i class="fal fa-plus"></i></span>{{ $t("Employee.form.addMore") }}
                  </div>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </div>

        <v-row>
          <v-col cols="12" md="12">
            <div class="form-title mt-8">
              <h1>
                <span><i class="fas fa-poll-people"></i></span>{{ $t("Employee.form.choose") }} HR
                / Line Manager
              </h1>
            </div>
          </v-col>
          <v-col cols="12" md="12">
            <v-row class="personal-input">
              <v-col cols="3" md="3" lg="3">
                <v-switch inset class="label-input" style="width: min-content" v-model="hasSelectRole"
                  :label="$t('Employee.form.choose')"></v-switch>
              </v-col>
              <v-col cols="8" md="8" lg="8" class="mt-4">
                <v-select outlined dense :disabled="!hasSelectRole" :items="listRoleUser" item-text="name" item-value="id"
                  multiple v-model="employee.roleUsers">
                </v-select>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <!-- ################################################################################## -->
        <v-row>
          <v-col cols="5" md="3">
            <div class="form-title mt-8">
              <h1>
                <span><i class="fas fa-fingerprint"></i></span>{{ $t("Employee.form.fingerScan") }}
              </h1>
            </div>
          </v-col>
          <v-col cols="7" md="9">
            <v-row class="personal-input">
              <v-col cols="12" md="5" lg="5">
                <label>{{ server_errors.finger_scan }}</label>
                <v-radio-group row v-model="employee.finger_scan">
                  <v-radio class="label-input" value="yes" :label="$t('Employee.form.yes')">
                  </v-radio>
                  <v-radio class="label-input" value="no" :label="$t('Employee.form.no')">
                  </v-radio>
                </v-radio-group>
              </v-col>
              <v-col cols="12" md="5" lg="5">
                <div class="scan-outside" v-if="employee.finger_scan == 'yes'">
                  <v-checkbox v-model="employee.scan_outside" value="yes"
                    :label="$t('Employee.form.scan_outside')"></v-checkbox>
                </div>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <!-- ################################################################################## -->
        <v-row v-if="companyProfile['include_salary_tax'] == 'yes'">
          <v-col cols="5" md="3">
            <div class="form-title mt-8">
              <h1>
                <span><i class="fal fa-money-check-edit-alt"></i></span>{{ $t("Employee.form.tax") }}
              </h1>
            </div>
          </v-col>
          <v-col cols="7" md="9">
            <v-row class="personal-input">
              <v-col cols="12" md="12" lg="12">
                <label>{{ server_errors.include_salary_tax }}</label>
                <v-radio-group row v-model="employee.include_salary_tax">
                  <v-radio class="label-input" value="employee" :label="$t('Employee.form.employeeTax')">
                  </v-radio>
                  <v-radio class="label-input" value="company" :label="$t('Employee.form.companyTax')">
                  </v-radio>
                </v-radio-group>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <!-- ################################################################################## -->
        <v-row v-if="companyProfile['sso_charge'] == 'employee'">
          <v-col cols="12" md="3">
            <div class="form-title mt-8">
              <h1>
                <span><i class="fal fa-money-check-edit-alt"></i></span>{{ $t("Employee.form.socialSecurity") }}
              </h1>
            </div>
          </v-col>
          <v-col cols="12" md="5">
            <v-row class="personal-input">
              <v-col cols="12" md="12" lg="12">
                <label class="error-validate">{{ server_errors.is_sso }}</label>
                <v-radio-group row v-model="employee.is_sso">
                  <v-radio class="label-input" value="yes" :label="$t('Employee.form.socialSecurityYes')">
                  </v-radio>
                  <v-radio class="label-input" value="no" :label="$t('Employee.form.socialSecurityNO')">
                  </v-radio>
                </v-radio-group>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" md="3">
            <label class="label-input">.</label>
            <v-text-field v-model="employee.sso_number" :placeholder="$t('Employee.form.sso_number')" outlined
              dense></v-text-field>
            <label class="error-validate">{{ server_errors.sso_number }}</label>
          </v-col>
        </v-row>
        <v-col cols="12" md="12">
          <v-btn class="btn-next btn-salary btn-save-change" style="float: right" @click="validateForm()"
            :loading="btnLoading">
            Update <span><i class="fal fa-long-arrow-right"></i></span>
          </v-btn>
        </v-col>
      </v-form>
    </div>
    <modalAddPosition :visible="modalAddPosition" @success="fetchPosition" @selectCurrentPosition="selectCurrentPosition"
      @change="(val) => (modalAddPosition = val)" />
    <modalAddDepartment :visible="modalAddDepartment" @success="fetchDepartment" @department="selectNewDepartment"
      @change="(val) => (modalAddDepartment = val)" />
    <modalEmployeeLevel :visible="modalEmployeeLevel" @success="fetchEmployeeLevel"
      @selectCurrentEmployeeLevel="selectCurrentEmployeeLevel" @change="(val) => (modalEmployeeLevel = val)" />
    <modalAddBranch :visible="modalAddBranch" @success="fetchBranch" @selectCurrentBranch="selectCurrentBranch"
      @change="(val) => (modalAddBranch = val)" />
  </div>
</template>

<script>
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import modalAddPosition from "@/components/employee/modal/modalAddPosition";
import modalAddDepartment from "@/components/employee/modal/modalAddDepartment";
import modalAddBranch from "@/components/employee/modal/modalAddBranch";
import modalEmployeeLevel from "@/components/employee/modal/modalEmployeeLevel";

export default {
  props: {
    employee: {},
  },
  components: {
    DatePicker,
    modalAddPosition,
    modalAddDepartment,
    modalAddBranch,
    modalEmployeeLevel,
  },
  data() {
    return {
      setEndContract: false,
      ManageSalaryPermission: false,
      btnLoading: false,
      hasSelectRole: false,
      modalAddPosition: false,
      modalAddDepartment: false,
      modalAddBranch: false,
      modalEmployeeLevel: false,
      nameRules: [(v) => !!v || "ກະລຸນາປ້ອນ..."],
      employee_id: this.$route.params.employee_id,
      listDepartment: [],
      items: {
        include_salary_tax: "employee",
        basic_salary: "",
        positionId: "",
        company_branch_id: "",
        employment_type: "",
        start_date: "",
        end_date: "",
        finger_scan: "",
        scan_outside: "",
        is_sso: "no",
        sso_number: "",
        company_department_id: "",
        is_line_manager: "",
        job_title_id: "",
        education_level_id: "",
        is_annual_bonus: "",
      },
      server_errors: {
        include_salary_tax: "",
        basic_salary: "",
        positionId: "",
        company_branch_id: "",
        employment_type: "",
        start_date: "",
        end_date: "",
        level_id: "",
        benefit_id: "",
        value: "",
        finger_scan: "",
        is_sso: "",
        sso_number: "",
        company_department_id: "",
        is_line_manager: "",
        job_title_id: "",
        education_level_id: "",
        is_annual_bonus: ""
      },
      benefits: [
        {
          benefit_id: "",
          value: "",
        },
      ],
      employment_type: [{ name: "FullTime" }, { name: "PartTime" }],
      position: [],
      branches: [],
      listBenefits: [],
      employeeType: [],
      employeeLevel: [],
      somethingRole: [],
      listRoleUser: [],
      companyProfile: [],
      jobTitles: [],
      educationLevels: [],
    };
  },
  methods: {
    async getUserPermission() {
      let UserPermission = JSON.parse(localStorage.getItem("userPermission"));
      let user_role = JSON.parse(localStorage.getItem("user_role"));
      if (user_role == "companyadmin") {
        this.ManageSalaryPermission = true;
      } else {
        UserPermission.forEach((element) => {
          if (element == "managesalary") {
            this.ManageSalaryPermission = true;
          }
        });
      }
    },
    async selectCurrentPosition(val) {
      if (val === 200) {
        await this.fetchPosition();
        const data = JSON.parse(JSON.stringify(this.position));
        this.employee.positionId = data.pop().id;
      }
    },
    async selectCurrentBranch(val) {
      if (val === 200) {
        await this.fetchBranch();
        const data = JSON.parse(JSON.stringify(this.branches));
        this.employee.company_branch_id = data.pop().id;
      }
    },

    async selectCurrentEmployeeLevel(val) {
      if (val === 200) {
        await this.fetchEmployeeLevel();
        const data = JSON.parse(JSON.stringify(this.employeeLevel));
        this.employee.level_id = data.pop().id;
      }
    },

    async selectNewDepartment(val) {
      if (val === 200) {
        await this.fetchDepartment();
        const data = JSON.parse(JSON.stringify(this.listDepartment));
        this.employee.department_id = data.pop().id;
      }
    },

    addEmployeeLevel() {
      this.modalEmployeeLevel = true;
    },
    addBranch() {
      this.modalAddBranch = true;
    },
    addNewPosition() {
      this.modalAddPosition = true;
    },
    addNewDepartment() {
      this.modalAddDepartment = true;
    },
    addFieldBenefit() {
      this.employee.benefits.push({
        benefit_id: "",
        value: "",
      });
    },
    fetchDepartment() {
      return this.$axios.get(`company/list/departments`).then((res) => {
        if (res.status === 200) {
          this.listDepartment = res.data.data;
          this.listDepartment.reverse();
        }
      });
    },
    removeFieldBenefit(item) {
      let idx = this.employee.benefits.indexOf(item);
      this.employee.benefits.splice(idx, 1);
    },
    fetchPosition() {
      this.$axios
        .get(`company/all-position`)
        .then((res) => {
          if (res.data.code === 200) {
            this.position = res.data.data;
            this.position.reverse();
          }
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.employee.positions = this.employee.positions.map((item) => {
            return {
              name: item.name,
              id: item.position_id,
            };
          });
        });
    },
    fetchBranch() {
      return this.$axios
        .get(`company/list/company/branches`)
        .then((res) => {
          if (res) {
            this.branches = res.data.companyBranches;
            this.branches.reverse();
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    fetchBenefits() {
      this.$axios
        .get(`company/list/benefits`)
        .then((res) => {
          if (res.status === 200) {
            this.listBenefits = res.data.benefits;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    fetchEmployeeLevel() {
      return this.$axios
        .get(`company/list/employee/levels`)
        .then((res) => {
          if (res.status === 200) {
            this.employeeLevel = res.data.listLevels;
            this.employeeLevel.reverse();
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    // Add News
    fetchComJobtitle() {
      return this.$axios.get(`company/jobtitle`).then((res) => {
        if (res.status === 200) {
          this.jobTitles = res.data.data;
          this.jobTitles.reverse();
        }
      });
    },

    fetchComEducationLevel() {
      return this.$axios.get(`company/education-level`).then((res) => {
        if (res.status === 200) {
          this.educationLevels = res.data.data;
          this.educationLevels.reverse();
        }
      });
    },

    validateForm() {
      if (this.$refs.form.validate()) {
        this.saveChange();
      }
    },

    getRoleUser() {
      this.$axios
        .get(`role/hr/linemanager`)
        .then((res) => {
          this.listRoleUser = res.data.data;
        })
        .catch(() => { });
    },

    saveChange() {
      let scan_outside;
      if (
        this.employee.scan_outside == null ||
        this.employee.scan_outside == "no"
      ) {
        scan_outside = "no";
      } else {
        scan_outside = "yes";
      }
      this.btnLoading = true;
      const items = {
        benefits: this.employee.benefits ?? [],
        base_salary: this.employee.basic_salary
          ? this.employee.basic_salary
          : 0,
        include_salary_tax:
          this.companyProfile["include_salary_tax"] == "yes"
            ? this.employee.include_salary_tax
            : "company",
        company_branch_id: this.employee.company_branch_id,
        employment_type: this.employee.employment_type,
        level_id: this.employee.level_id,
        start_date: this.employee.start_date,
        end_date: this.employee.end_date,
        finger_scan: this.employee.finger_scan,
        scan_outside: scan_outside,
        is_sso:
          this.companyProfile["sso_charge"] == "employee"
            ? this.employee.is_sso
            : "no",
        sso_number: this.employee.sso_number,
        is_line_manager: this.employee.is_line_manager,
        positionId: this.employee.positions.map((item) => {
          return item.id ?? item;
        }),
        company_department_id: this.employee.department_id,
        roleId: this.employee.roleUsers.map((item) => {
          return item.id ?? item;
        }),
        job_title_id: this.employee.job_title_id,
        education_level_id: this.employee.education_level_id,
        is_annual_bonus: this.employee.is_annual_bonus
      };
      this.$axios
        .put(`company/edit/employee/salary/info/${this.employee_id}`, items)
        .then((res) => {
          if (res.status === 200) {
            this.btnLoading = false;
            setTimeout(() => {
              this.$notification.OpenNotification_AddItem_OnSuccess(
                "top-right",
                "primary",
                3000
              );
            }, 300);
          }
        })
        .catch((error) => {
          this.btnLoading = false;
          if (error.response.status === 422) {
            let obj = error.response.data.errors;
            if (!obj) {
              obj = error.response.data;
              let res = "";
              for (let [key, value] of Object.entries(obj)) {
                res = value;
              }
              this.$notification.ShowErrors("top-right", "danger", 3000, res);
            } else {
              for (let [key, value] of Object.entries(obj)) {
                this.server_errors[key] = value[0];
                this.$notification.ShowErrors(
                  "top-right",
                  "danger",
                  3000,
                  (this.server_errors[key] = value[0])
                );
              }
            }
          }
          if (error.response.status === 500) {
            this.$notification.ShowErrors(
              "top-right",
              "danger",
              3000,
              "server error"
            );
          }
        });
    },
    getCompanyProfile() {
      this.companyProfile = JSON.parse(
        localStorage.getItem("sng-company-profile")
      );
    },
  },

  watch: {
    employee: function (val) {
      if (val.roleUsers.length > 0) {
        return (this.hasSelectRole = true);
      }
    },
  },

  created() {
    this.getUserPermission();
    this.getCompanyProfile();
    this.fetchPosition();
    this.fetchBranch();
    this.fetchBenefits();
    this.fetchEmployeeLevel();
    this.fetchDepartment();
    this.getRoleUser();
    this.fetchComJobtitle();
    this.fetchComEducationLevel();
  },
};
</script>

<style scoped lang="scss">
.form {
  margin-top: 40px;
  width: 900px;
  height: auto;
  margin-bottom: 80px;

  .form-title {
    font-family: $font-family;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;

    h1 {
      font-size: 1.2rem;
      letter-spacing: 1px;
      font-weight: normal;
      color: #0978ea;

      span {
        color: #0978ea;
        margin-right: 5px;
      }
    }
  }

  .personal-input {
    width: 100%;
    height: 100%;
    padding-top: 20px;
    border-bottom: 1px solid #eeeeee;
    padding-bottom: 20px;
  }

  .removeContact {
    width: 80px;
    height: 80px;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    i {
      font-size: 1.4rem;
      margin-top: 8px;
      color: #ff3d6a;
      cursor: pointer;
    }
  }

  .scan-outside {
    margin-left: 30px;
    font-family: $font-family;
  }

  .btn-addFieldContact {
    width: 200px;
    height: 41px;
    background-color: $main-btn-color;
    margin-bottom: 30px;
    border-radius: 4px;
    color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    font-family: $font-family;
  }

  .btn-salary {
    width: 200px;
  }
}
</style>
