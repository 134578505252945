<template>
  <div class="content lfont">
    <div class="section-header">
      <h1>
        <i class="fal fa-house" style="margin: 0 10px 0 0"></i>
        {{ company_name }}
      </h1>
      <h4>{{ $t("Company.userDetail.title") }}</h4>
      <v-row dense>
        <v-col cols="3" md="3" lg="3">
          <label class="label-input">{{ $t("ssoReport.startDate") }}</label>
          <DatePicker
            class="date-time"
            style="width: 100%"
            type="date"
            valueType="format"
            slot="activator"
            :append-to-body="true"
            name="founding_date"
            v-model="start_date"
            @change="fetchCompanyEmployee"
          ></DatePicker>
        </v-col>
        <v-col cols="3" md="3" lg="3">
          <label class="label-input">{{ $t("ssoReport.endDate") }}</label>
          <DatePicker
            class="date-time"
            style="width: 100%"
            type="date"
            valueType="format"
            slot="activator"
            :append-to-body="true"
            v-model="end_date"
            @change="fetchCompanyEmployee"
            name="founding_date"
          >
          </DatePicker>
        </v-col> </v-row
      ><br />
    </div>
    <div class="section-body">
      <v-row>
        <v-col cols="12" md="4" lg="4">
          <h3>
            <i class="fal fa-users"></i> ນຳໃຊ້ທັງໝົດ:
            {{
              emplyeeData.close_user.length +
              emplyeeData.del_user.length +
              emplyeeData.open_user.length
            }}
          </h3>

          <h4 class="text-primary">
            <a @click="show_employee('open_user')"
              ><i class="fal fa-user"></i> ນຳໃຊ້ຕອນນີ້:
              {{ emplyeeData.open_user.length }}</a
            >
          </h4>
          <h4>
            <a class="text-warning" @click="show_employee('close_user')"
              ><i class="fal fa-user"></i> ຕອນນີ້ອອກ:
              {{ emplyeeData.close_user.length }}</a
            >
          </h4>
          <h4>
            <a class="text-danger" @click="show_employee('del_user')"
              ><i class="fal fa-user"></i> ຕອນນີ້ຖືກລຶບ:
              {{ emplyeeData.del_user.length }}</a
            >
          </h4>
        </v-col>
        <v-col cols="12" lg="8" md="8">
          <v-btn depressed color="success" class="btn" @click="exportExcel()">
            <v-icon> mdi-export </v-icon> Excel </v-btn
          ><br />
          <v-data-table
            :headers="headers"
            :items="emplyeeInfo"
            :items-per-page="10"
            class="elevation-1"
          ></v-data-table>
        </v-col>
      </v-row>
      <!--  -->
      <Pagination
        v-if="pagination.total_pages > 1"
        :pagination="pagination"
        :offset="offset"
        @paginate="fetchCompanyEmployee"
      />
    </div>
    <div class="section-footer"></div>
    <Loading v-if="isLoading" />
  </div>
</template>

<script>
import moment from "moment";
import DatePicker from "vue2-datepicker";
import Loading from "@/components/Loading";
import "vue2-datepicker/index.css";
import Pagination from "@/components/Paginate/Pagination";
export default {
  components: { Loading, Pagination },
  data() {
    return {
      headers: [
        { text: "ລະຫັດ", value: "emp_number" },
        { text: "ຊື່,ນາມສະກຸນພະນັກງານ", value: "name" },
        { text: "ເວລາເພີ່ມ", value: "created_at" },
        { text: "ເວລາປ່ຽນແປງ", value: "updated_at" },
      ],
      emplyeeInfo: [
        {
          name: null,
          emp_number: null,
          created_at: null,
          updated_at: null,
        },
      ],
      start_date: new Date().toISOString().slice(0, 10),
      end_date: new Date().toISOString().slice(0, 10),
      offset: 10,
      pagination: {},
      per_page: 10,
      company_id: null,
      company_name: null,
      isLoading: false,
      emplyeeData: {
        close_user: {},
        del_user: {},
        open_user: {},
      },
    };
  },
  methods: {
    exportExcel() {
      this.isLoading = true;
      this.$axios
        .post(
          `admin/export/employee/${this.company_id}`,
          {
            start_date: this.start_date,
            end_date: this.end_date,
          },
          {
            responseType: "blob",
          }
        )
        .then((res) => {
          if (res.status === 200) {
            this.isLoading = false;
            const fileUrl = window.URL.createObjectURL(new Blob([res.data]));
            const fileLink = document.createElement("a");
            fileLink.href = fileUrl;
            fileLink.setAttribute("download", "employee-holiday.xlsx");
            document.body.appendChild(fileLink);
            fileLink.click();
            document.body.removeChild(fileLink);
            this.isLoading = false;
          }
        })
        .catch((err) => {
          this.isLoading = false;
          console.log(err);
        });
    },
    show_employee(status) {
      if (status == "close_user") {
        this.emplyeeInfo = this.emplyeeData.close_user;
      } else if (status == "del_user") {
        this.emplyeeInfo = this.emplyeeData.del_user;
      } else if (status == "open_user") {
        this.emplyeeInfo = this.emplyeeData.open_user;
      }
    },
    getFontColor(status, del) {
      if (del != null) {
        return "red";
      } else if (status == "open") {
        return "blue";
      } else if (status == "close") {
        return "#ff9200";
      }
    },
    fetchCompanyEmployee() {
      this.isLoading = true;
      this.$axios
        .get(`admin/company/employee/${this.company_id}`, {
          params: {
            start_date: this.start_date,
            end_date: this.end_date,
          },
        })
        .then((res) => {
          if (res) {
            this.isLoading = false;
            this.emplyeeData = res.data.data;
          }
        })
        .catch((error) => {
          console.log(error);
          this.isLoading = false;
        });
    },
  },
  created() {
    this.company_id = this.$route.params.company_id;
    this.company_name = this.$route.params.company_name;
    this.fetchCompanyEmployee();

    let date = new Date();
    let firstDate = new Date(date.getFullYear(), date.getMonth(), 1);
    this.start_date = moment(firstDate).format("YYYY-MM-DD");
  },
};
</script>

<style scoped lang="scss">
.content {
  padding: 20px;
  .section-header {
    border-bottom: 1px solid;
    margin-bottom: 30px;
  }
}
.table-header {
  font-weight: 900 !important;
  font-size: 16px !important;
  border-bottom: 1px solid !important;
}
</style>