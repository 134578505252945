<template>
  <div class="main-stepper">
    <v-stepper v-model="nextSteps">
      <v-stepper-header>
        <template v-for="n in steps">
          <v-stepper-step :key="`${n.id}-step`" :step="n.id" :complete="nextSteps > n.id" editable>
            <span v-if="$i18n.locale === 'en'">{{ n.stepName_en }}</span>
            <span v-else> {{ n.stepName }}</span>
          </v-stepper-step>

          <v-divider v-if="n.id !== steps.id" :key="n.id"></v-divider>
        </template>
      </v-stepper-header>

      <v-stepper-items>
        <v-stepper-content v-for="n in steps" :key="`${n.id}-content`" :step="n.id">
          <personal :employee="employee" @success="NextStep(1)" v-if="n.id === 1" />
          <contactInfo :employee="employee" @success="NextStep(2)" v-if="n.id === 2" />
          <accountInfo :employee="employee" @success="NextStep(3)" v-if="n.id === 3" />
          <salary :employee="employee" @success="NextStep(4)" v-if="n.id === 4" />
          <Holiday :employee="employee" @success="saveItems" v-if="n.id === 5" />
          <!--          <employeeStatus :employee="employee"  v-if="n.id === 6"/>-->
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>
  </div>
</template>
<script>
import { mapActions } from "vuex";
import personal from "@/components/employee/stepsEdit/personal";
import contactInfo from "@/components/employee/stepsEdit/contactInfo";
import accountInfo from "@/components/employee/stepsEdit/accountInfo";
import salary from "@/components/employee/stepsEdit/salary";
import Holiday from "@/components/employee/stepsEdit/Holiday";
// import employeeStatus from "@/components/employee/stepsEdit/employeeStatus";

export default {
  components: {
    personal,
    contactInfo,
    accountInfo,
    salary,
    Holiday,
    // employeeStatus
  },
  data() {
    return {
      employee_id: this.$store.getters["Employee/employee_id"],
      employee: {},
      employeeHoliday: [],
      nextSteps: 1,
      steps: [
        {
          id: 1,
          stepName: "ຂໍ້ມູນພື້ນຖານ",
          stepName_en: "Basic Info",
        },
        {
          id: 2,
          stepName: "ທີ່ຢູ່ຂໍ້ມູນຕິດຕໍ່",
          stepName_en: "Contact Info",
        },
        {
          id: 3,
          stepName: "ຂໍ້ມູນບັນຊີ",
          stepName_en: "Account Info",
        },
        {
          id: 4,
          stepName: "ຂໍ້ມູນເງິນເດືອນ",
          stepName_en: "Salary Info",
        },

        {
          id: 5,
          stepName: "ຂໍ້ມູນວັນພັກ",
          stepName_en: "Holiday",
        },
        // {
        //   id: 6,
        //   stepName: "ສະຖານະພະນັກງານ",
        //   stepName_en: "Employee Status"
        // },
      ],
      identity_Card: [
        {
          card_type: "identity_card",
          name: "ບັດປະຈຳຕົວ",
        },
        {
          card_type: "social_security_card",
          name: "ບັດປະກັນສັງຄົມ",
        },
        {
          card_type: "passport",
          name: "ໜັງສຶຜ່ານແດນ",
        },
      ],
    };
  },
  methods: {
    ...mapActions({
      saveEmployee: "Employee/saveEmployee",
    }),
    NextStep(step) {
      this.nextStep(step);
    },
    nextStep(n) {
      if (n === this.steps) {
        this.nextSteps = 1;
      } else {
        this.nextSteps = n + 1;
      }
    },

    saveItems() {
      this.saveEmployee()
        .then((res) => {
          if (res.data.code === 200) {
            setTimeout(() => {
              this.$router.push({
                name: "employee.index",
              });
              this.$notification.OpenNotification_AddItem_OnSuccess(
                "top-right",
                "primary",
                3000
              );
            }, 300);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    fetchHoliday() {
      this.$axios
        .get(`company/list/holidays`)
        .then((res) => {
          if (res.status === 200) {
            this.employeeHoliday = res.data.data;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    fetchEmployee_item() {
      this.$axios
        .get(`company/find/employee/editing/${this.employee_id}`)
        .then((res) => {
          if (res.status === 200) {
            this.employee = res.data.data;
            this.employee.card_type =
              this.identity_Card.filter((item) => {
                return item.card_type === this.employee.card_type;
              })[0] || {};
          }
        });
    },
  },
  created() {
    if (this.employee_id === null) {
      this.employee_id = this.$route.params.employee_id;
    }
    this.fetchEmployee_item();
    this.fetchHoliday();
  },
};
</script>
<style scoped lang="scss">
.main-stepper {
  width: 100%;
  height: auto;
}
</style>

