<template>
  <div class="step-layout">
    <div class="step-content">
      <v-form class="form-contact" ref="form">
        <v-row>
          <v-col cols="5" md="3">
            <div class="form-title">
              <h1><span><i class="fas fa-home"></i></span>{{ $t("Employee.form.address") }}</h1>
            </div>
          </v-col>
          <v-col cols="7" md="9">
            <v-row class="personal-input">
              <v-col cols="6" md="6">
                <label class="label-input"><span class="text-alert">*</span>{{ $t("Employee.form.province") }}</label>
                <v-select v-model="employee.province_id" dense outlined :items="provinces" item-text="name"
                  :rules="nameRules" required item-value="id">
                </v-select>
              </v-col>
              <v-col cols="6" md="6">
                <label class="label-input"><span class="text-alert">*</span>{{ $t("Employee.form.district") }}</label>
                <v-select v-model="employee.district_id" dense outlined :items="districts" :rules="nameRules" required
                  item-text="name" @change="selectDistrictId($event)" item-value="id">
                </v-select>
              </v-col>
              <v-col cols="12" md="12" style="position: relative">
                <label class="label-input"><span class="text-alert">*</span>{{ $t("Employee.form.village") }}</label>
                <!-- <v-select
                    dense
                    outlined
                    :rules="nameRules"
                    required
                    :items="listVillage"
                    item-text="name"
                    item-value="id"
                    v-model="employee.village_id"
                    :hint="`${server_errors.village_id}`"
                    persistent-hint
                >
                </v-select> -->
                <v-autocomplete filled dense item-text="name" item-value="id" outlined :items="listVillage" hide-selected
                  :rules="nameRules" :hint="`${server_errors.village_id}`" persistent-hint requried
                  v-model="employee.village_id">
                  <template v-slot:item="data">
                    <template>
                      <v-list-item-content>
                        <v-list-item-title v-html="data.item.name"></v-list-item-title>
                      </v-list-item-content>
                    </template>
                  </template>
                </v-autocomplete>
                <div class="add-village" @click="addNewVillage">
                  <i class="fal fa-plus"></i>
                </div>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="5" md="3">
            <div class="form-title">
              <h1><span><i class="fas fa-phone-alt"></i></span>{{ $t("Employee.form.empContact") }}</h1>
            </div>
          </v-col>
          <v-col cols="7" md="9">
            <v-row class="personal-input">
              <v-col class="d-flex flex-row pa-0" cols="12" md="12" v-for="(item, index) in employee.contact"
                :key="index">
                <v-col cols="4" md="4">
                  <label class="label-input">{{ $t("Employee.form.contact") }}</label>
                  <v-select outlined dense :items="contactType" v-model="item.contact_type_id" item-text="name"
                    item-value="id" persistent-hint>
                  </v-select>
                </v-col>
                <v-col cols="8" md="8">
                  <label class="label-input">{{ $t("Employee.form.value") }}</label>
                  <v-text-field dense outlined required v-model="item.value" persistent-hint>
                  </v-text-field>
                </v-col>
                <span>
                  <div class="removeContact" v-show="employee.contact.length > 1">
                    <i class="fas fa-minus-circle" @click="removeFieldContact(item)"></i>
                  </div>
                </span>
              </v-col>
              <v-col cols="12">
                <div class="btn-addFieldContact" @click="addFieldContact()">
                  <span class="mr-2"><i class="fal fa-plus"></i></span>{{ $t("Employee.form.addMore") }}
                </div>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="5" md="3">
            <div class="form-title">
              <h1><span><i class="fas fa-id-card"></i></span>{{ $t("Employee.form.Emergency Contact") }}</h1>
            </div>
          </v-col>
          <v-col cols="7" md="9">
            <v-row class="personal-input">
              <v-col>
                <label class="label-input"><span class="text-alert">*</span>{{ $t("Employee.form.Emergency Contact")
                }}</label>
                <v-text-field outlined dense v-model="employee.emergency_contact">

                </v-text-field>
              </v-col>
              <v-col>
                <label class="label-input"><span class="text-alert">*</span>{{ $t("Employee.form.relationShip") }}</label>
                <v-text-field outlined dense v-model="employee.relationship" :hint="`${server_errors.relationship}`"
                  persistent-hint>

                </v-text-field>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="5" md="3">
            <div class="form-title">
              <h1><span><i class="fas fa-id-card"></i></span><span class="text-alert">*</span>{{
                $t("Employee.form.identity") }}</h1>
            </div>
          </v-col>

          <v-col cols="7" md="9">
            <v-row class="personal-input">
              <v-col cols="5">
                <label class="label-input"><span class="text-alert">*</span>{{ $t("Employee.form.cardType") }}</label>
                <v-select outlined dense v-model="employee.card_type" :items="identity_Card"
                  :item-text="item => $t('Employee.form.cardTypes.' + item.name)" item-value="card_type"
                  :rules="nameRules" required :hint="`${server_errors.card_type}`" persistent-hint>
                </v-select>
              </v-col>

              <v-col cols="7">
                <label class="label-input"><span class="text-alert">*</span>{{ $t("Employee.form.cardTypeValue")
                }}</label>
                <v-text-field outlined dense :rules="nameRules" required v-model="employee.card_id"
                  :hint="`${server_errors.card_id}`">
                </v-text-field>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-col cols="12" md="12">
          <v-btn class="btn-next btn-save-change" @click="validateForm()" :loading="btnLoading">
            Update <span><i class="fal fa-long-arrow-right"></i></span>
          </v-btn>
        </v-col>
      </v-form>
      <modalAddVillage :districtId="districtId" :visible="modalAddVillage" @success="onVillageCreated"
        @change="(val) => modalAddVillage = val" />
    </div>
  </div>
</template>

<script>
import modalAddVillage from "@/components/employee/modal/modalAddVillage";

export default {
  components: {
    modalAddVillage,
  },
  props: {
    employee: {
      default() {
        return { province_id: null, district_id: null };
      }
    },
  },
  data() {
    return {
      btnLoading: false,
      nameRules: [
        v => !!v || 'ກະລຸນາປ້ອນ...'
      ],
      rules: {
        email: value => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          return pattern.test(value) || 'Invalid e-mail.'
        },
      },
      employee_id: this.$route.params.employee_id,
      contactType: [],
      identity_Card: [
        {
          card_type: "identity_card",
          name: "identity_card"
        },
        {
          card_type: "social_security_card",
          name: "social_security_card"
        },
        {
          card_type: "passport",
          name: "passport"
        }
      ],
      provinces: [],
      districts: [],
      filterDistricts: [],
      items: {
        district_id: "",
        village: "",
        card_type: "",
        card_id: "",
        value: "",
        relationship: ""
      },
      server_errors: {
        district_id: "",
        village_id: "",
        card_type: "",
        card_id: "",
        relationship: "",
      },
      contact: [
        {
          contact_type_id: "",
          value: "",
        }
      ],
      listVillage: [],
      village: [],
      modalAddVillage: false,
      districtId: "",
    }
  },
  methods: {
    async onVillageCreated() {
      await this.fetchVillage();
      this.selectDistrictId(this.districtId);
    },

    selectDistrictId(event) {
      this.districtId = event;
      this.listVillage = this.village.filter(item => item.district_id === event);
    },

    fetchVillage() {
      return this.$axios.get(`list/addresses`).then((res) => {
        if (res.status === 200) {
          this.village = res.data.villages;
        }
      })
    },
    addFieldContact() {
      this.employee.contact.push({
        contact_type_id: "",
        value: "",
      })
    },
    removeFieldContact(item) {
      let idx = this.employee.contact.indexOf(item);
      this.employee.contact.splice(idx, 1)
    },

    fetchContactType() {
      this.$axios.get('company/contact-type').then((res) => {
        if (res.data.code === 200) {
          this.contactType = res.data.data;
        }
      })
    },
    fetchLocation() {
      return this.$axios.get('list/addresses').then((res) => {
        const items = res.data;
        this.provinces = items.provinces;
        this.filterDistricts = items.districts;
      }).catch((error) => {
        console.log(error)
      });
    },

    FilterDistricts(provinceID) {
      this.districts = this.filterDistricts.filter(item => {
        if (item.province_id === provinceID) {
          return item;
        }
      });
    },
    validateForm() {
      if (this.$refs.form.validate()) {
        this.saveChange();
      }
    },
    saveChange() {
      this.btnLoading = true;
      const items = {
        district_id: this.employee.district_id,
        village_id: this.employee.village_id,
        contacts: this.employee.contact || [],
        card_type: this.employee.card_type.card_type || this.employee.card_type,
        card_id: this.employee.card_id,
        emergency_contact: this.employee.emergency_contact,
        relationship: this.employee.relationship,
      }
      this.$axios.put(`company/edit/employee/contact/info/${this.employee_id}`, items).then((res) => {
        if (res.status === 200) {
          this.btnLoading = false;
          setTimeout(() => {
            this.$notification.OpenNotification_AddItem_OnSuccess('top-right', 'primary', 3000);
          }, 300);
        }
      }).catch((error) => {
        this.btnLoading = false;
        if (error.response.status === 422) {
          let obj = error.response.data.errors;
          if (!obj) {
            obj = error.response.data;
            let res = "";
            for (let [key, value] of Object.entries(obj)) {
              res = value;
            }
            this.$notification.ShowErrors("top-right", "danger", 3000, res);
          } else {
            for (let [key, value] of Object.entries(obj)) {
              this.server_errors[key] = value[0];
              this.$notification.ShowErrors(
                "top-right",
                "danger",
                3000,
                (this.server_errors[key] = value[0])
              );
            }
          }
        }
        this.btnLoading = false;
      })
    },
    validateType(e) {
      if (e === 1) {
        // console.log(this.rules.email())
        return this.rules.email()
      }
    },

    addNewVillage() {
      this.modalAddVillage = true;
    }
  },

  watch: {
    'employee.province_id': function (provinceID) {
      this.FilterDistricts(provinceID);
    },
  },

  async created() {
    this.fetchContactType();
    this.fetchVillage();
    await this.fetchLocation();
    this.FilterDistricts(this.employee.province_id);
    await this.fetchVillage();
    this.selectDistrictId(this.employee.district_id)
  }
}
</script>

<style scoped lang="scss">
.form-contact {
  margin-top: 40px;
  width: 900px;
  height: auto;
  margin-bottom: 100px;

  .form-title {
    font-family: $font-family;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;

    h1 {
      font-size: 1.2rem;
      letter-spacing: 1px;
      font-weight: normal;
      color: #0978EA;

      span {
        color: #0978EA;
        margin-right: 5px;
      }
    }
  }

  .personal-input {
    width: 100%;
    height: 100%;
    //  background-color: green;
    padding-top: 20px;
    border-bottom: 1px solid #eeeeee;
    padding-bottom: 20px;
  }

  .removeContact {
    width: 80px;
    height: 80px;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    i {
      font-size: 1.4rem;
      margin-top: 8px;
      color: #ff3d6a;
      cursor: pointer;
    }

  }

  .btn-addFieldContact {
    width: 200px;
    height: 41px;
    background-color: $main-btn-color;
    margin-bottom: 30px;
    border-radius: 4px;
    color: #FFFFFF;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    font-family: $font-family;
  }

  .add-village {
    width: 40px;
    height: 40px;
    border-radius: 8px;
    background-color: $main-btn-color;
    position: absolute;
    right: -40px;
    top: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    i {
      font-size: 22px;
      color: #FFFFFF;
    }
  }
}
</style>
